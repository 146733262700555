import { useI18n } from 'vue-i18n';
import { useBreakpoints } from "@vueuse/core";
import { computed, onBeforeMount, onBeforeUnmount, onMounted } from "vue";
import { useComponentChain } from "@/utils/errorReportingUtils.js";
import { useRouter } from "vue-router";

export function useShared() {
  const bp = useBreakpoints({ mobile: 0, desktop: 768 }).active();

  return {
    ...useI18n({
      inheritLocale: true,
      useScope: 'local',
    }),
    componentInfo: {
      chain: useComponentChain(),
    },
    mobile: computed(() => bp.value === 'mobile'),
    desktop: computed(() => bp.value === 'desktop'),
  };
}

export function redirectUnless(cond, route) {
  onBeforeMount(() => {
    if (!cond) {
      const router = useRouter();
      router.push(route);
    }
  });
}

export function useBodyClass(classname) {
  onMounted(() => document.body.classList.add(classname));
  onBeforeUnmount(() => document.body.classList.remove(classname));
}

export function optionList(arr) {
  return arr.map(v => ({ value: v, label: String(v) }));
}

export function scrollBehavior(to, from, savedPosition) {
  if (from.path === to.path) {
    return { x: window.scrollX, y: window.scrollY };
  }

  return savedPosition ? savedPosition : { left: 0, top: 0 }
}

export function useSmoothScroll() {
  onMounted(() => document.querySelector('html').style.scrollBehavior = 'smooth');
  onBeforeUnmount(() => document.querySelector('html').style.scrollBehavior = 'unset');
}